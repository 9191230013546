import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get(stateId) {
    let result = await axios.get(
      `${API_ROUTES.processlawyerexpired.get}/${stateId}`
    );

    return result;
  }
};
